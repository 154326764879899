<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">
          {{ edit ? "Edit" : "New" }} {{ edit ? inventory.name : "Item" }}
        </h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <div v-if="step === 1">
        <ValidationObserver>
          <form @submit.prevent="createInventoryItem" class="text-left mt-10">
            <ValidationProvider name="Item name" v-slot="{ errors }">
              <div class="mb-5" v-if="inventory_type.inventory_item_id === ''">
                <label for="item_name">Item Name</label>
                <input
                  id="name"
                  type="text"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Inventory Item Name"
                  v-model="inventory_item_name"
                /><br />
              </div>
            </ValidationProvider>
            <ValidationProvider name="Item name" v-slot="{ errors }">
              <div class="mb-5" v-if="inventory_item_name === ''">
                <label for="item_name">Items already Available</label>
                <select
                  id="name"
                  type="text"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Inventory Item ID"
                  v-model="inventory_type.inventory_item_id"
                >
                  <option value="">Items already created</option>
                  <option
                    v-for="item in inventory_items"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.inventory_item_name }}
                  </option>
                </select>
                <br />
              </div>
            </ValidationProvider>
            <button
              class="w-full mt-64 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
              :class="
                (inventory_item_name !== '' &&
                  inventory_type.inventory_item_id !== '') ||
                submitting
                  ? 'bg-gray-300'
                  : 'bg-primary'
              "
              :disabled="
                (inventory_item_name !== '' &&
                  inventory_type.inventory_item_id !== '') ||
                submitting
              "
            >
              Continue
            </button>
          </form>
        </ValidationObserver>
      </div>
      <div v-else-if="step === 2">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="createInventoryType" class="text-left mt-10">
            <ValidationProvider
              name="Item type name"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-5">
                <label for="item_name">Item Type Name</label>
                <input
                  id="name"
                  type="text"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Inventory Item Type Name"
                  v-model="inventory_type.inventory_types_name"
                /><br />
              </div>
            </ValidationProvider>
            <button
              class="w-full mt-96 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
              :class="invalid || submitting ? 'bg-gray-300' : 'bg-primary'"
              :disabled="invalid || submitting"
            >
              Continue
            </button>
          </form>
        </ValidationObserver>
      </div>
      <template v-else-if="step === 3">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="createNewInventory" class="text-left mt-10">
            <ValidationProvider
              name="Number of items"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-5">
                <label for="item_name">Number of Items</label>
                <input
                  id="name"
                  type="number"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Inventory Item Name"
                  v-model="inventory.number_of_items"
                /><br />
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Price per items"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-5">
                <label for="item_name">Unit Price per Item</label>
                <input
                  id="name"
                  type="number"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Unit Price per Item"
                  v-model="inventory.price_per_items"
                /><br />
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Price per items"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-5">
                <label for="item_name">Total Price of Items</label>
                <input
                  id="name"
                  type="number"
                  disabled
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Unit Price per Item"
                  v-model="totalPrice"
                /><br />
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="Price per items"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="mb-5">
                <label for="item_name">Notify when quantity drops below</label>
                <input
                  id="name"
                  type="number"
                  class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                  :class="errors[0] ? 'border border-red-400' : ''"
                  placeholder="Unit Price per Item"
                  v-model="inventory.notify_when_lower"
                /><br />
              </div>
            </ValidationProvider>
            <button
              class="w-full mt-16 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
              :class="invalid || submitting ? 'bg-gray-300' : 'bg-primary'"
              :disabled="invalid || submitting"
            >
              Save Item
            </button>
          </form>
        </ValidationObserver>
      </template>
    </div>
  </div>
</template>

<script>
import {
  createItem,
  createType,
  createInventory,
  fetchAllItems,
  editInventory,
} from "@/services/inventory";

export default {
  name: "AddNewInventory",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    edit: {
      type: Boolean,
      required: false,
    },
    inventory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inventory_item_name: "",
      inventory_items: [],
      inventory_type: {
        inventory_types_name: "",
        inventory_item_id: "",
      },
      submitting: false,
      step: 1,
    };
  },
  computed: {
    totalPrice() {
      return this.inventory.number_of_items * this.inventory.price_per_items;
    },
  },
  methods: {
    async createInventoryItem() {
      this.submitting = true;
      if (this.inventory_type.inventory_item_id === "") {
        try {
          const response = await createItem({
            inventory_item_name: this.inventory_item_name,
          });

          this.inventory_type.inventory_item_id = response.data[1].id;
          this.step = 2;
        } catch (error) {
          this.$toast.error(error.response.data.message || error);
          console.log(error);
        }
      } else {
        this.step = 2;
      }

      this.submitting = false;
    },
    async createInventoryType() {
      this.submitting = true;
      try {
        const response = await createType(this.inventory_type);
        this.inventory.inventory_item_id = response.data[1].inventory_item_id;
        this.inventory.inventory_type_id = response.data[1].id;
        this.step = 3;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }

      this.submitting = false;
    },
    async createNewInventory() {
      this.submitting = true;
      let payload = {
        number_of_items: this.inventory.number_of_items,
        price_per_items: this.inventory.price_per_items,
        inventory_type_id: this.inventory.inventory_type_id,
        inventory_item_id: this.inventory.inventory_item_id,
        totalprice_of_items: this.totalPrice,
        notify_when_lower: this.inventory.notify_when_lower,
      };
      if (this.edit === false) {
        try {
          await createInventory(payload);
          this.$toast.success("Inventory item created");
          this.$emit("created");
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await editInventory(this.inventory.id, payload);
          console.log(response);
          this.$emit("created");
        } catch (error) {
          console.log(error);
          this.$toast.error(error.response.data.message || error);
        }
      }

      this.submitting = false;
    },
  },
  async mounted() {
    if (this.edit === false) {
      const response = await fetchAllItems();
      this.inventory_items = response.data.inventory_item;
    } else {
      this.step = 3;
    }
  },
};
</script>
